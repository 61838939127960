<template>
  <div class="p-2 bgCommon">
    <b-card no-body class="">
        <img src="~@/assets/images/banner/coming-soon-2.png" alt="">

        <!-- <b-card-body class="p-2">
            <p class="comeing-soon-text">開發中</p>
        </b-card-body> -->
    </b-card>
  </div>
</template>
    
<script>
import {
    BCard,
    BCardBody,
} from "bootstrap-vue";

export default {

  components: {
    BCard,
    BCardBody,
  },
  data() {
    return {
    };
  },
  methods: {

  },
  setup() {
  },
};
</script>
    

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
// .whole-page{
//   width:100%;
//   height:100vh;
// }
// .comeing-soon-text{
//   position: absolute;
//   left:50%;
//   top:50%;
//   transform: translate(-50%,-50%);
//   font-size: 108px;
// }
</style>
    